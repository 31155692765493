import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useToast } from 'shared/hooks'

import { HolidayForm } from 'domains/holiday/components'

import { usePostHoliday } from '../../hooks/usePostHoliday'
import { HolidayPayload } from 'services/holiday/types'

const CreateHoliday = () => {
  const { addToast } = useToast()
  const navigate = useNavigate()

  const { mutate: mutateCreateHoliday } = usePostHoliday()

  const onSave = useCallback(
    (data: HolidayPayload) => {
      const { year, ...rest } = data

      const payload: HolidayPayload = {
        ...rest,
        ...(!!year && { year }),
      }

      mutateCreateHoliday(payload, {
        onSuccess: () => {
          addToast({ message: 'Feriado cadastrado com sucesso.' })
          navigate(-1)
        },
        onError: () => {
          addToast({
            message: 'Erro ao cadastrar feriado. Tente novamente.',
            type: 'alert',
          })
        },
      })
    },
    [addToast, navigate, mutateCreateHoliday],
  )

  return <HolidayForm onGoBack={() => navigate(-1)} onSubmit={onSave} />
}

export default CreateHoliday
